<template>
  <div class="circle-item">
    <div class="circle-bar" style="position: relative">
      <div
        class="progressbar-text"
        style="
          position: absolute;
          left: 50%;
          top: 50%;
          padding: 0px;
          margin: 0px;
          transform: translate(-50%, -50%);
          color: rgb(20, 20, 20);
        "
      >
        {{ value }}
      </div>
    </div>
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: ["value", "text"],
};
</script>
