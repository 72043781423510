<template>
  <section class="player-single-wrap">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="captain-bage">Team</div>
          <h4 class="player-name">{{ team.name }}</h4>
        </div>
        <div class="col-md-3">
          <div class="player-photo">
            <img class="img-responsive" :src="team.image" alt="player" />
          </div>
        </div>
        <div class="col-md-9">
          <div class="player-hockey-wrap">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="stats">
                  <div class="col-lg-6 pointsContainer">
                    <div class="point">
                      <span class="number">{{ team.matches }}</span> Spiele
                    </div>
                    <div class="point">
                      <span class="number">{{ team.wins }}</span>
                      Siege
                    </div>
                  </div>
                  <Statistics :team="team" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Statistics from "@/app/teams/components/Statistics.vue";
export default {
  components: {
    Statistics,
  },
  data() {
    return {
      teams: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    team() {
      return this.teams.filter((team) => team.id == this.id)[0];
    },
  },
  beforeMount() {
    this.teams = this.$store.getters.teams;
  },
};
</script>

<style scoped>
.pointsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  align-content: center;
}
.point {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 20px 20px;
  font-size: 20px;
  font-weight: bold;
}

.number {
  margin-right: 10px;
}
.row:after,
.row:before {
  display: table;
  content: " ";
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.player-single-wrap .captain-bage {
  margin: 30px 20px 29px 0;
  padding: 0 0 0 10px;
  height: 26px;
  position: relative;
  vertical-align: top;
  line-height: 24px;
  display: inline-block;
  font-size: 15px;
  text-transform: uppercase;
  color: #fcfcfc;
  font-family: Montserrat, sans-serif;
  background: #fc0;
}
.player-single-wrap .captain-bage:before {
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  transform: skew(-10deg);
  background: #fc0;
  z-index: -1;
}
.player-single-wrap .player-name {
  display: inline-block;
  margin: 8px 20px 29px 0;
}
.player-photo {
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
}
.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive,
.thumbnail a > img,
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.player-hockey-wrap {
  background-color: #fcfcfc;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.player-hockey-wrap:before {
  position: absolute;
  content: "";
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: -100%;
  right: 75%;
  transform: skew(-10deg);
}
.player-hockey-wrap .stats {
  position: relative;
  padding: 30px;
}
.player-hockey-wrap .number {
  color: #fc0;
  font-size: 40px;
  font-family: Montserrat;
}
.player-hockey-wrap .name {
  font-family: Montserrat;
  color: #141414;
  font-size: 24px;
  text-transform: uppercase;
}
.player-hockey-wrap .country {
  font-size: 10px;
  font-family: Montserrat;
  color: #3d3d3d;
}
.wrapper-circle {
  display: inline-flex;
  width: 100%;
}

/* €media for smartphones */
@media (max-width: 767px) {
  .player-photo {
    /* center image */
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}
</style>
