<template>
  <div class="name">
    <h4>Statistiken</h4>
    <div class="wrapper-circle">
      <CircleItem class="circle" :value="team.lost" text="Verloren" />
      <CircleItem class="circle" :value="team.draw" text="Unentschieden" />
      <CircleItem class="circle" :value="team.goals" text="Tore" />
      <CircleItem class="circle" :value="team.opponentGoals" text="Gegentore" />
      <CircleItem class="circle" :value="team.points" text="Punkte" />
      <CircleItem class="circle" :value="`${winRate}%`" text="Gewinnrate" />
    </div>
  </div>
</template>

<script>
import CircleItem from "@/app/teams/components/CircleItem.vue";
export default {
  components: {
    CircleItem,
  },
  props: ["team"],
  computed: {
    winRate() {
      const games = this.team.wins + this.team.lost + this.team.draw;
      return Math.ceil((this.team.wins / games) * 100);
    },
  },
};
</script>

<style scoped>
.wrapper-circle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
}
.circle {
  display: flex;
  align-items: center;
  padding: 5px;
}

@media (max-width: 768px) {
  h4 {
    text-align: center;
  }
}
</style>
